<template>
  <div class="page container login p-4">
    <div class="columns">
      <div class="column is-6 is-offset-3">
        <div class="has-text-centered">
          <img
            class="anon-avatar"
            src="@/assets/img/anon-avatar.png"
            alt="identifícate"
          >
        </div>
        <b-field>
          <b-input
            ref="username"
            v-model="username"
            type="email"
            placeholder="Usuario (email)"
            required
            icon-pack="fas"
            icon="envelope"
            maxlength="100"
            @keyup.native.enter="login"
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <b-input
            ref="password"
            v-model="password"
            type="password"
            placeholder="Contraseña"
            required
            icon-pack="fas"
            icon="key"
            maxlength="20"
            password-reveal
            @blur="validateForm"
            @keyup.native.enter="login"
          />
        </b-field>
        <div class="mt-3">
          <b-button
            type="is-primary is-uppercase"
            expanded
            @click="login"
          >
            Enviar
          </b-button>
        </div>
        <div class="mt-3 has-text-centered">
          <b-button
            type="is-text"
            @click.prevent="requestNewPassword"
          >
            ¿Olvidaste tu contraseña?, cámbiala aquí
          </b-button>
          <b-button
            type="is-text"
            @click.prevent="registerNewAccount"
          >
            ¿No tienes cuenta?, regístrate aquí
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import deviceTokenMixin from "@/mixins/deviceTokenMixin";

export default {
  title: 'Identifícate',
  name: 'Login',
  mixins: [deviceTokenMixin],
  data: function () {
    return {
      isLoading: false,
      isValid: false,
      username: '',
      password: ''
    }
  },
  computed: {},
  mounted() {
    this.focusForm();
  },
  methods: {
    validateForm() {
      const validateFields = ["username", "password"];
      let isValidFormData = true

      validateFields.forEach((item) => {
        if (this.$refs.hasOwnProperty(item)) {
          const itemIsValid = this.$refs[item].checkHtml5Validity()
          isValidFormData = isValidFormData && itemIsValid
        }
      })

      this.isValid = isValidFormData
    },
    async login() {
      this.validateForm()

      if (this.isValid) {
        try {
          await this.$store.dispatch('initLoading')
          const user = await this.$api.user.login(this.username, this.password)

          await this.$store.dispatch('finishLoading')
          await this.$store.dispatch('login', user)
          this.$api.init(user.token)

          this.$msg.success(`¡Hola ${user.firstname}!`)

          await this.updateStoredToken()
          await this.$store.dispatch('loadNotifications')
          let redirectTo = this.$route.query?.redirect_to
          if (!redirectTo) {
            redirectTo = '/'
          }
          await this.$router.replace(redirectTo)
        } catch (error) {
          await this.$store.dispatch('finishLoading')
          const data = error.response.data
          this.$msg.error(data.message)
        }
      }
    },
    requestNewPassword() {
      this.$router.push('forgotten-password')
    },
    registerNewAccount() {
      this.$router.push({name: 'register'})
    },
    focusForm() {
      try {
        this.$refs.username.focus()
      } catch (e) {
        console.warn('no se ha podido establecer el foco en el primer campo')
      }
    }
  }
}
</script>
